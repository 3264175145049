
/**
 * vue全局过滤器
 */

/**
 * 时间过滤器
 * @param val 值
 * @param format 格式
 */
const globalFilters = {}
globalFilters.install = function (Vue) {
  Vue.filter('dateFormat', (val, format) => {
    return val
  })
}
export default globalFilters
