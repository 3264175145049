const BUSINESS = [{
  path: '/company-business',
  name: '公司管理',
  // 按需加载路由
  component: () => import('@/views/business-manage/company/company'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '公司管理',
      path: '/company-business'
    }]
  }
}, {
  path: '/drawback-business',
  name: '退款申请管理',
  // 按需加载路由
  component: () => import('@/views/business-manage/drawback/drawback'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '退款申请管理',
      path: '/drawback-business'
    }]
  }
}, {
  path: '/freight-business',
  name: '运费模板管理',
  // 按需加载路由
  component: () => import('@/views/business-manage/freight/freight'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '运费模板管理',
      path: '/freight-business'
    }]
  }
}, {
  path: '/goods-business',
  name: '商品管理',
  // 按需加载路由
  component: () => import('@/views/business-manage/goods/goods'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '商品管理',
      path: '/goods-business'
    }]
  }
}, {
  path: '/income-business',
  name: '收益统计',
  // 按需加载路由
  component: () => import('@/views/business-manage/income/income'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '收益统计',
      path: '/income-business'
    }]
  }
}, {
  path: '/order-business',
  name: '订单管理',
  // 按需加载路由
  component: () => import('@/views/business-manage/order/order'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '订单管理',
      path: '/order-business'
    }]
  }
}]
export default BUSINESS
