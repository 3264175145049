/**
 * 系统日志
 */
const SYSTEM_LOG = [{
  path: '/loginLog',
  name: 'LoginLog',
  alias: '/syslog-loginLog',
  // 登录日志
  component: () => import('@/views/system-log/login-log/LoginLog'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '登录日志',
      path: '/syslog-loginLog'
    }]
  }
}, {
  path: '/operationLog',
  name: 'OperationLog',
  alias: '/syslog-operationLog',
  // 操作日志
  component: () => import('@/views/system-log/operation-log/OperationLog'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '操作日志',
      path: '/syslog-loginLog'
    }]
  }
}, {
  path: '/fileLog',
  name: 'FileLog',
  alias: '/syslog-fileLog',
  // 文件上传记录
  component: () => import('@/views/system-log/file-log/FileLog'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '文件上传记录',
      path: '/syslog-fileLog'
    }]
  }
}, {
  path: '/version',
  name: 'Version',
  alias: '/syslog-version',
  // 版本管理
  component: () => import('@/views/system-log/version/Version'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '版本管理',
      path: '/syslog-version'
    }]

  }
}, {
  path: '/backup',
  name: 'Backup',
  alias: '/syslog-backup',
  // 数据备份
  component: () => import('@/views/system-log/backup/Backup'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '数据备份',
      path: '/syslog-backup'
    }]

  }
}, {
  path: '/backupFile',
  name: 'BackupFile',
  alias: '/syslog-backupFile',
  // 文件备份
  component: () => import('@/views/system-log/backup-file/BackupFileList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '文件备份',
      path: '/syslog-backupFile'
    }]

  }
}, {
  path: '/msgHistory',
  name: 'MsgHistory',
  alias: '/syslog-msgHistory',
  // 消息发送历史
  component: () => import('@/views/system-log/msg-history/MsgHistoryList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '消息发送历史',
      path: '/syslog-msgHistory'
    }]

  }
}]
export default SYSTEM_LOG
