/*
 * @Author: duanyunlong
 * @since: 2020-07-16 17:04:31
 * @lastTime: 2020-07-28 10:20:25
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\router\modules\user.js
 * @message: 系统管理模块路由规则
 */
/**
 * 账户管理
 */
const USER = [{
  path: '/unit',
  name: 'UnitManage',
  // 单位管理
  component: () => import('@/views/user/unit-manage/UnitManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '单位管理',
      path: '/unit'
    }]
  }
}, {
  path: '/dept',
  name: 'DeptManage',
  // 部门管理
  component: () => import('@/views/user/dept-manage/DeptManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '部门管理',
      path: '/dept'
    }]
  }
}, {
  path: '/role',
  name: 'RoleManage',
  // 角色管理
  component: () => import('@/views/user/role-manage/RoleManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '角色管理',
      path: '/role'
    }]
  }
}, {
  path: '/user',
  name: 'UserManage',
  // 用户管理
  component: () => import('@/views/user/user-manage/UserManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '用户管理',
      path: '/user'
    }]
  }
}, {
  path: '/account-addressBook',
  name: 'AddressBook',
  // 用户管理
  component: () => import('@/views/user/user-manage/AddressBook'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '通讯录',
      path: '/account-addressBook'
    }]
  }
}]
export default USER
