/*
 * @Author: duanyunlong
 * @since: 2020-05-24 09:18:32
 * @lastTime: 2020-07-29 10:14:14
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\config\store.js
 * @message: vuex常量等配置
 */
/**
 * vuex方法变量配置
 */
const STATE_KEY = {
  saveUserInfo: 'SAVE_USER_INFO',
  updateHeader: 'UPDATE_HEADER',
  saveUserMenu: 'SAVE_USER_MENU',
  resetState: 'RESET_STATE',
  resetSubmitKey: 'RESET_SUBMIT_KEY',
  resetUserState: 'RESET_USER_STATE',
  resetAppState: 'RESET_APP_STATE',
  updateRiskState: 'UPDATE_RISK_STATE',
  saveAuthToken: 'SAVE_AUTH_TOKEN'
}
export default STATE_KEY
