import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import STATE_KEY from '../config/store'
import REQUEST_KEY from '../config/request'

Vue.use(VueRouter)
let childRoutes = [
]
// 路由去中心化
const routerModules = require.context('./modules', false, /\.js$/)
routerModules.keys().forEach(filename => {
  childRoutes = childRoutes.concat(routerModules(filename).default || routerModules(filename))
})
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [{
    path: '/login/:type?',
    name: 'Login',
    // 路由别名
    alias: '/',
    // 按需加载路由
    component: () => import('@/views/login/LoginDefault'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      // 该参数用于挂载当前路由的按钮权限信息，不管有没有定义该参数，按钮的权限都将挂载在当前参数上面，如无特殊情况，该参数必须定义
      btnAuthList: []
    }
  }, {
    path: '/loginP',
    name: 'LoginPhone',
    // 按需加载路由
    component: () => import('@/views/login/LoginPhone'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/loginBind/:id/:type',
    name: 'LoginBind',
    // 按需加载路由
    component: () => import('@/views/login/LoginBind'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/register',
    name: 'Register',
    // 按需加载路由
    component: () => import('@/views/login/Register'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/forgetCode',
    name: 'LoginForgetPassword',
    // 按需加载路由
    component: () => import('@/views/login/LoginForgetPassword'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/layout',
    name: 'Layout',
    // 按需加载路由
    component: () => import('@/views/layout/TheLayout'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: [],
      data: [{
        title: '首页',
        path: ''
      }]

    },
    children: childRoutes,
    // 重定向
    redirect: (to) => {
      const userMenu = store.getters.userMenuAuth || []
      const flag = userMenu.some(item => ('/' + item.linkUrl) === REQUEST_KEY.serviceConfig.homeUrl && item.isShow === 1)
      if (flag) {
        return REQUEST_KEY.serviceConfig.homeUrl
      } else {
        return REQUEST_KEY.serviceConfig.noPermissionUrl
      }
    }
  }, {
    path: '/dingTalkAuthentication',
    name: 'DingTalkAuthentication',
    // 重定向到登录页
    redirect: (to, form) => {
      const state = to.query.state
      if (state === 'dingTalk') {
        return '/personSetting/dingTalk'
      }
      return '/login/dingTalk'
    },
    // 按需加载路由
    // component: () => import('@/views/login/DingTalkAuthentication'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/weChatAuthentication',
    name: 'WeChatAuthentication',
    // 重定向到登录页
    redirect: (to, form) => {
      const state = to.query.state
      if (state === 'weChat') {
        return '/personSetting/weChat'
      }
      return '/login/weChat'
    },
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '*',
    name: '404',
    component: () => import('@/views/error/404'),
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }]
})

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
  const isLogin = to.meta.isLogin || false
  if (!isLogin) {
    const isRegister = process.env.VUE_APP_IS_SHOW_REGISTER ? process.env.VUE_APP_IS_SHOW_REGISTER === 'false' : false
    const isPhone = process.env.VUE_APP_IS_PHONE_LOGIN ? process.env.VUE_APP_IS_PHONE_LOGIN === 'false' : false
    const isCode = process.env.VUE_APP_IS_SHOW_CODE ? process.env.VUE_APP_IS_SHOW_CODE === 'false' : false
    const { path } = to
    if (isRegister && path === '/register') {
      next({ path: '/' })
      return
    }
    if (isPhone && path === '/loginP') {
      next({ path: '/' })
      return
    }
    if (isCode && path === '/forgetCode') {
      next({ path: '/' })
      return
    }
    next()
  } else {
    const userId = store.getters.userInfo.userId
    if (userId) {
      const isAuth = to.meta.isAuth || false
      const userMenu = store.getters.userMenuAuth || []
      const arr = userMenu.filter(item => ('/' + item.linkUrl) === to.path && item.isShow === 1)
      if (arr.length > 0) {
        const currRouterInfo = arr[0]
        const permissions = currRouterInfo.permissions || ''
        to.meta.btnAuthList = permissions.split(',')
      }
      if (!isAuth || arr.length > 0) {
        next()
      } else {
        next({ path: REQUEST_KEY.serviceConfig.noPermissionUrl })
      }
    } else {
      next({ path: '/' })
    }
  }
})
/**
 * 返回首页
 */
export function goHome () {
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.layoutUrl) {
    router.push(REQUEST_KEY.serviceConfig.layoutUrl)
  }
}
/**
 * 401页面
 */
export function goNoLimits () {
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.noPermissionUrl) {
    router.push(REQUEST_KEY.serviceConfig.noPermissionUrl)
  }
}
/**
 * 返回登录页
 */
export function goLogin () {
  // 清空缓存
  localStorage.removeItem(process.env.VUE_APP_SYS_EN_NAME)
  // 重置state
  store.dispatch(STATE_KEY.resetState)
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.loginUrl) {
    router.push(REQUEST_KEY.serviceConfig.loginUrl)
  }
}

export default router
