import { render, staticRenderFns } from "./App.vue?vue&type=template&id=62d8a0c2&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=62d8a0c2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports