/*
 * @Author: duanyunlong
 * @since: 2020-07-16 17:04:31
 * @lastTime: 2020-07-28 10:19:38
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\router\modules\system.js
 * @message: 系统模块路由规则
 */
/**
 * 系统管理
 */
const SYSTEM = [{
  path: '/system-menu',
  name: 'MenuManage',
  // 菜单管理
  component: () => import('@/views/system/menu-manage/MenuManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '菜单管理',
      path: '/system-menu'
    }]
  }
}, {
  path: '/system-parameter',
  name: 'ParameterManage',
  // 系统参数管理
  component: () => import('@/views/system/parameter-manage/ParameterManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '系统参数管理',
      path: '/system-parameter'
    }]
  }
}, {
  path: '/system-msgTemplate',
  name: 'MessageManage',
  // 消息模板
  component: () => import('@/views/system/message-manage/MessageManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '消息模板',
      path: '/system-msgTemplate'
    }]
  }
},
{
  path: '/system-dictionary',
  name: 'DictionaryManage',
  // 数据字典管理
  component: () => import('@/views/system/dictionary-manage/DictionaryManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '数据字典管理',
      path: '/system-dictionary'
    }]
  }
}, {
  path: '/system-dictionaryGroup',
  name: 'DictionaryType',
  // 数据字典类型管理
  component: () => import('@/views/system/dictionary-type/DictionaryType'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '数据字典类型管理',
      path: '/system-dictionaryGroup'
    }]
  }
}, {
  path: '/system-ssoCorpApp',
  name: 'SsoCorpAppManage',
  // 第三方应用授权
  component: () => import('@/views/system/sso-corp-app/SsoCorpAppManage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '第三方应用授权',
      path: '/system-ssoCorpApp'
    }]
  }
},
{
  path: '/system-SsoInnerSite',
  name: 'SsoInnerSite',
  // 第三方应用授权
  component: () => import('@/views/system/sso-inner-site/SsoInnerSite'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: [],
    data: [{
      title: '内嵌第三方应用',
      path: '/system-SsoInnerSite'
    }]
  }
}, {
  path: '/system-SsoTemp',
  name: 'SsoTemp',
  // 第三方应用授权
  component: () => import('@/views/system/sso-inner-site/SsoTemp'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: false,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: [],
    data: [{
      title: '单点登录临时页',
      path: '/system-SsoTemp'
    }]
  }
}, {
  path: '/ThirdPartySsoLogin',
  name: 'ThirdPartySsoLogin',
  // 第三方应用授权
  component: () => import('@/views/system/sso-inner-site/ThirdPartySsoLogin'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: false,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: [],
    data: [{
      title: '第三方单点登录临时页',
      path: '/ThirdPartySsoLogin'
    }]
  }
}]
export default SYSTEM
