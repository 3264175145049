/*
 * @Author: duanyunlong
 * @since: 2020-07-16 17:04:31
 * @lastTime: 2020-07-29 10:15:27
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\store\modules\app.js
 * @message: vuex,app模块
 */
import STATE_KEY from '../../config/store'
import { uuid } from '../../utils/data'
const APP = {
  state: {
    // 生成submitKey，防止用户重复提交
    submitKey: uuid(16, 16),
    // 是否需要验证码
    isRisk: false
  },
  mutations: {
    /**
     *  更改isRisk状态
     * @param {Boolean} flag
     */
    [STATE_KEY.updateRiskState] (state, flag) {
      state.isRisk = flag
    },
    /**
     * 重置submitKey
     * @param {String} state
     */
    [STATE_KEY.resetSubmitKey] (state) {
      state.submitKey = uuid(16, 16)
    },
    /**
     * 重置当前模块
     * @param state
     */
    [STATE_KEY.resetAppState] (state) {
      state.isRisk = false
      state.submitKey = uuid(16, 16)
    }
  }
}
export default APP
