/*
 * @Author: duanyunlong
 * @since: 2020-05-24 09:18:32
 * @lastTime: 2020-07-28 10:19:04
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\router\modules\person.js
 * @message: 个人设置页面
 */
const PERSON_ROUTER = [{
  path: '/personCenter',
  name: 'personCenter',
  component: resolve => require(['@/views/person/personCenter'], resolve),
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
}, {
  path: '/personSetting/:type?',
  name: 'personSetting',
  component: resolve => require(['@/views/person/personSetting'], resolve),
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
}, {
  path: '/msg-task',
  name: 'MsgTask',
  component: resolve => require(['@/views/person/MsgTask'], resolve),
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
}]
export default PERSON_ROUTER
