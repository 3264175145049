const INDEX = [{
  path: '/fileList',
  name: 'fileList',
  // 按需加载路由
  component: () => import('@/views/fileManage/fileList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}, {
  path: '/documentList',
  name: 'documentList',
  // 按需加载路由
  component: () => import('@/views/documentManage/documentList'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: true,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}]
export default INDEX
