/**
 * 判断是否其中一个
 * @param val
 * @param any
 */
export function oneOf (val, any) {
  for (let i = 0; i < any.length; i++) {
    if (val === any[i]) {
      return true
    }
  }
  return false
}

/**
 * 判断数据类型
 * @param data
 */
export function typeOf (data) {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(data)]
}
/**
 * 检查是否为数字或字母
 * @param val
 * @returns {boolean}
 */
export function isLetterOrNum (val) {
  const reg = /^[a-z0-9]+$/i
  return reg.test(val)
}

/**
 * 身份证号码验证
 * @author Enzo
 * @date 2019-12-5
 */
export function isIdCard (val) {
  const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  return reg.test(val)
}

/**
 * 验证密码复杂性，中等级别，包含数字和字符，（密码中必须包含字母、数字，长度[6-20]）
 * @author Enzo
 * @date 2019-12-5
 */
export function passwordComplexityGeneral (value) {
  var regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,20}')
  if (regex.test(value + '')) {
    return true
  } else {
    return false
  }
}

/**
 * 判断是否为图片格式
 * @param img
 * @returns {boolean}
 */
export function isImg (img) {
  const reg = /\.jpg$|\.jpeg$|\.gif|\.png$/i
  return reg.test(img)
}

/**
 * 判断是否为空
 * @param p
 * @returns {boolean}
 */
export function isNull (p) {
  return !isNotNull(p)
}

/**
 * 判断是否不为空
 * @param p
 * @returns {boolean}
 */
export function isNotNull (p) {
  return !p
}

/**
 * 判断是否为数组
 * @param array
 * @returns {boolean}
 */
export function isArray (array) {
  return array != null && array instanceof Array
}

/**
 * 判断是否不是数组
 * @param array
 * @returns {boolean}
 */
export function isNotArray (array) {
  return !isArray(array)
}

/**
 * 判断是否为数字
 * @param number
 * @returns {boolean}
 */
export function isNumber (number) {
  return number != null && number instanceof Number
}

/**
 * 判断是否不为数字
 * @param number
 * @returns {boolean}
 */
export function isNotNumber (number) {
  return !isNumber(number)
}

/**
 * 判断是否为时间类型
 * @param date
 * @returns {boolean}
 */
export function isDate (date) {
  return date != null && date instanceof Date
}

/**
 * 判断是否不为时间类型
 * @param date
 * @returns {boolean}
 */
export function isNotDate (date) {
  return !isDate(date)
}

/**
 * 判断是否为字符串
 * @param string
 * @returns {boolean}
 */
export function isString (string) {
  return string != null && typeof string === 'string'
}

/**
 * 判断是否不为字符串
 * @param string
 * @returns {boolean}
 */
export function isNotString (string) {
  return !isString(string)
}

/**
 * 是否是json字符串
 * @param content
 */
export function isJSON (content) {
  if (typeof content === 'string') {
    try {
      const obj = JSON.parse(content)
      return !!(typeof obj === 'object' && obj)
    } catch (e) {
      return false
    }
  }
}

/**
 * 判断一个数是否为整数
 * @param number
 * @returns {boolean}
 */
export function isInteger (number) {
  return number % 1 === 0
}

/**
 * 判断一个数是否不为整数
 * @param number
 * @returns {boolean}
 */
export function isNotInteger (number) {
  return !isInteger(number)
}

/**
 *  手机号验证
 */
export function isPhone (val) {
  const reg = /^1(3|4|5|6|7|8|9)\d{9}$/
  return reg.test(val)
}

/**
 * 用户账号验证,只能包括数字字母的组合，长度为4-15位
 */
export function isAccount (val) {
  const reg = /^[A-Za-z0-9]{4,15}$/
  return reg.test(val)
}

/**
 * 邮箱验证
 */
export function isEmail (val) {
  const reg = /^\w+@[a-z0-9]+\.[a-z]{2,4}$/
  return reg.test(val)
}

/**
 * 是否为一个有效的url地址
 * @param val
 * @returns {boolean}
 */
export function isUrl (val) {
  const reg = /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i
  return reg.test(val)
}
