import { typeOf } from './verification'

/**
 * 深度copy
 * data
 */
function deepCopy (data) {
  const type = typeOf(data)
  let o
  if (type === 'array') {
    o = []
    // 递归
    for (let i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]))
    }
  } else if (type === 'object') {
    o = {}
    for (const i in data) {
      o[i] = deepCopy(data[i])
    }
  } else {
    return data
  }
  return o
}
export { deepCopy }

/**
 * 数组类型转换
 * @param numberArray
 * @returns {string[]}
 */
export function changeArrayType (type, array) {
  const typeRange = [String, Number, Boolean]
  if (typeOf(array) === 'array' && typeRange.some(item => item === type)) {
    return array.map(type)
  }
}
/**
 * 将字符串转换为头峰标识（demo-demo=>demoDemo）
 * @param str
 */
export function changeHump (str) {
  const reg = /-(\w)/g
  return str.replace(reg, ($0, $1) => {
    return $1.toUpperCase()
  })
}

/**
 *  将数据格式化成属性值为map样式的格式
 * @param tree
 * @param map
 * @returns {[]}
 */
function handleDataFormat (data = [], map = {}) {
  const result = []
  data.forEach((item, index) => {
    // 读取 map 的键值映射
    const value = item[map.value] || item[map.value] === 0 ? item[map.value] : index + 1
    const label = item[map.label] || item[map.label] === 0 ? item[map.label] : ''
    let children = item[map.children] ? item[map.children] : []
    // 如果有子节点，递归
    if (children) {
      children = handleDataFormat(children, map)
    }
    result.push({ value, label, children })
  })
  return result
}
export { handleDataFormat }

/**
 * 获取地址栏参数值
 * @param param
 * @return {*}
 */
export function getQueryByName (param) {
  const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) { return decodeURI(r[2]) }
  return null
}

/**
 * 去除文本的html标签
 * @param content
 */
export function removeHtmlTag (content) {
  return content.replace(/<[^>]+>/g, '').replace(/\n/g, '')
}

/**
 * 随机打乱数组
 * @param array
 */
export function randArray (array) {
  const arr = [...array]
  for (let j, x, i = this.length; i; j = parseInt(Math.random() * i), x = arr[--i], arr[i] = arr[j], arr[j] = x) {}
  return arr
}

/**
 * 计算文件大小
 * @param value
 * @param unit
 * @param finalUnit
 * @returns {string}
 */
export function calculateStorageSpace (value = 0, unit = 'Byte', finalUnit) {
  const unitArray = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB']
  let index = unitArray.findIndex(item => item.toLowerCase() === unit.toLowerCase())
  if (index < 0) {
    index = 0
  }
  while (value > 1024) {
    value /= 1024
    index++
  }
  if (index > 0) {
    value = value.toFixed(2)
  }
  return value + unitArray[index]
}

/**
 * 数组数据逐项累加
 * @param array
 * @returns {Array}
 */
export function summation (array = []) {
  const newArray = []
  array.forEach((item, index) => {
    if (index !== 0) {
      newArray.push(newArray[index - 1] + item)
    } else {
      newArray.push(item)
    }
  })
  return newArray
}

/**
 * base64转blob
 * @param base64
 * @returns {Blob}
 */
export function base64toBlob (base64) {
  const array = base64.split(',')
  const mime = array[0].match(/:(.*?);/)[1]
  const blobStr = atob(array[1])
  let n = blobStr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = blobStr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

/**
 * blob转base64
 * @param blob
 * @returns {Promise<any>}
 */
export function blobToBase64 (blob) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = e => {
      resolve(e.target.result)
    }
    reader.readAsDataURL(blob)
  })
}

/**
 * 按key将dataSource树变成一个数组
 * @param {Array} dataSource
 * @param {String} key
 * @param {Array} arr
 */
function getListByTree (dataSource = [], arr = [], key = 'children') {
  dataSource.forEach(element => {
    arr.push(element)
    if (typeOf(element[key]) === 'array' && element[key].length > 0) {
      getListByTree(element[key], arr, key)
    }
  })
  return arr
}

/*
  指定长度和基数
*/
export function uuid (len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  let i = 0
  radix = radix || chars.length
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
  } else {
    // rfc4122, version 4 form
    let r
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }

  return uuid.join('')
}
export { getListByTree }
