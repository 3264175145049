/*
 * @Author: duanyunlong
 * @since: 2020-07-16 17:04:31
 * @lastTime: 2020-07-28 10:18:56
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\router\modules\index.js
 * @message: 首页
 */
const INDEX = [{
  path: '/index',
  name: 'index',
  // 按需加载路由
  component: () => import('@/views/index'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: true,
    // 是否需要鉴权
    isAuth: true,
    btnAuthList: []
  }
}]
export default INDEX
