/*
 * @Author: duanyunlong
 * @since: 2020-05-24 09:18:32
 * @lastTime: 2020-07-28 11:06:42
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\directive\index.js
 * @message: 全局自定义指令
 */
/**
 * vue全局指令
 */
import router from '../router/index'
const globalDirective = {}
globalDirective.install = function (Vue) {
  Vue.directive('focus', (el) => {
    el.focus()
  })
  Vue.directive('auth', {
    inserted (el, binding) {
      const currentRoute = router.currentRoute || {}
      const meta = currentRoute.meta || {}
      const btnAuthList = meta.btnAuthList || []
      const value = binding.value || binding.expression || ''
      const flag = btnAuthList.includes(value)
      if (!flag) {
        if (el.parentNode) {
          el.parentNode.removeChild(el)
        } else {
          el.style.display = 'none'
        }
      }
    }
  })
}
export default globalDirective
