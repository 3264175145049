/*
 * @Author: duanyunlong
 * @since: 2020-06-29 09:54:48
 * @lastTime: 2020-07-29 08:09:41
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\store\getters.js
 * @message: vuex getters对象
 */
/**
 * 所有vuex值获取都在当前模块获取
 * @type {{}}
 */
const GETTERS = {
  userInfo: state => state.user.userInfo,
  userMenu: state => state.user.userMenu,
  userMenuAuth: state => state.user.userMenuAuth,
  isRisk: state => state.app.isRisk,
  submitKey: state => state.app.submitKey,
  authToken: state => state.user.authToken
}
export default GETTERS
