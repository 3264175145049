/*
  @Author:SiFan
  @Time:2020/12/28 11:01
*/
const INDEX = [{
  path: '/home',
  name: 'home',
  // 按需加载路由
  component: () => import('@/views/home/HomePage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: false,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: false,
    btnAuthList: []
  }
}]
export default INDEX
